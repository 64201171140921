import React, { useContext, useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { ApiContext } from "../ApiContext";
import "./Device.css";
import { Toaster, toast } from "react-hot-toast";
import Loading from "./Loading";
import { Helmet } from "react-helmet";

function ConfirmEmail() {
    const title = "Kajo Spotti - Sähköpostin vahvistus"
    const { ApiCall, ga4 } = useContext(ApiContext);

    const { token } = useParams();
    const [loading, setLoading] = useState(true);
    const [confirmRequested, setConfirmRequested] = useState(false)
    const [result, setResult] = useState();


    useEffect(() => {
        if (token && ApiCall && !confirmRequested) {
            setConfirmRequested(true)
            ApiCall("POST", "user/confirm", {
                token: token
            })
                .then((res) => {
                    console.log(res);
                    toast.success("Sähköposti vahvistettu onnistuneesti")
                    setLoading(false)
                    setResult(true)
                    ga4.event({
                        category: "user",
                        action: "email_confirmed",
                        label: "Email confirmation",
                    })
                })
                .catch((err) => {
                    console.error(err);
                    toast.error("Virhe sähköpostiosoitetta vahvistaessa")
                    setLoading(false)
                    setResult(false)
                });
        }
    }, [token, ApiCall]);

    return (
        <div className="main-content">
            <Toaster />
            <Helmet>
                <title>{title}</title>
                <meta property="og:title" content={title} />
            </Helmet>
            {loading && <Loading />}
            {!loading && result && <p>Sähköposti vahvistettu. Voit nyt sulkea tämän sivun.</p>}
            {!loading && !result && <p>Sähköpostin vahvistus ei onnistunut. Yritä uudelleen tilaamalla uusi linkki. Linkki on voimassa lähetyshetkestä vuorokauden.</p>}
        </div>
    )
}

export default ConfirmEmail;
