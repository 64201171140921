import React, { useState } from "react";
import { Helmet } from "react-helmet";

import DeviceStatus from "../DeviceStatus";
import DeviceSettings from "../DeviceSettings";
import "../Device.css";

const DEFAULT_DEVICE_CONFIG = require("spot-automation-common").DEFAULT_DEVICE_CONFIG

function SandboxDevice() {
    const title = "Kajo Spotti - Hiekkalaatikko"
    const description = "Demoympäristö laitteen asetuksiin tutustumiseen ilman rekisteröitymistä"

    const device = {
        mac: "sandbox",
    }
    const [config, setConfig] = useState(DEFAULT_DEVICE_CONFIG)
    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
            </Helmet>
            <div className="main-content">
                <div className="container">
                    <h1>Demolaite</h1>
                    <p>Tämä on hiekkalaatikkoympäristö, jossa voit vapaasti tutustua laitteiden hallintaan.</p>
                    <div style={{ width: "100%", marginTop: "0.5em" }}>
                        <div className="device-info">
                            <DeviceStatus config={config} showTicks={false} details={true} />
                        </div>
                    </div>
                    <div
                        style={{
                            flexDirection: "row",
                            display: "flex",
                            justifyContent: "space-between",
                            flexWrap: "wrap",
                            width: "100%",
                        }}
                    >
                        <DeviceSettings
                            device={device}
                            config={config}
                            setConfig={setConfig}
                            enableSubmit={false}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default SandboxDevice;
