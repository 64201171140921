import { useCallback, useEffect, useRef, useState } from "react"

export function useAutoplay(emblaApi) {
    const [autoplayIsPlaying, setAutoplayIsPlaying] = useState(false)

    const toggleAutoplay = useCallback(() => {
        const autoplay = emblaApi?.plugins()?.autoplay
        if (!autoplay) return

        const playOrStop = autoplay.isPlaying() ? autoplay.stop : autoplay.play
        playOrStop()
    }, [emblaApi])

    useEffect(() => {
        const autoplay = emblaApi?.plugins()?.autoplay
        if (!autoplay) return
        setAutoplayIsPlaying(autoplay.isPlaying())
        emblaApi
            .on('autoplay:play', () => setAutoplayIsPlaying(true))
            .on('autoplay:stop', () => setAutoplayIsPlaying(false))
            .on('reInit', () => setAutoplayIsPlaying(autoplay.isPlaying()))
    }, [emblaApi])

    return {
        autoplayIsPlaying,
        toggleAutoplay
    }
}

export function useAutoplayProgress(emblaApi, progressNode) {
    const [showAutoplayProgress, setShowAutoplayProgress] = useState(false)
    const animationName = useRef('')
    const timeoutId = useRef(0)
    const rafId = useRef(0)

    const startProgress = useCallback((timeUntilNext) => {
        const node = progressNode.current

        if (!node) return
        if (timeUntilNext === null) return

        if (!animationName.current) {
            const style = window.getComputedStyle(node)
            animationName.current = style.animationName
        }

        node.style.animationName = 'none'
        node.style.transform = 'translate3d(0,0,0)'

        rafId.current = window.requestAnimationFrame(() => {
            timeoutId.current = window.setTimeout(() => {
                node.style.animationName = animationName.current
                node.style.animationDuration = `${timeUntilNext}ms`
            }, 0)
        })

        setShowAutoplayProgress(true)
    }, [])

    useEffect(() => {
        const autoplay = emblaApi?.plugins()?.autoplay
        if (!autoplay) return
        if (autoplay.isPlaying()) {
            setShowAutoplayProgress(true)
            startProgress(autoplay.timeUntilNext())
        }
        emblaApi
            .on('autoplay:timerset', () => startProgress(autoplay.timeUntilNext()))
            .on('autoplay:timerstopped', () => setShowAutoplayProgress(false))
    }, [emblaApi])

    useEffect(() => {
        return () => {
            cancelAnimationFrame(rafId.current)
            clearTimeout(timeoutId.current)
        }
    }, [])

    return {
        showAutoplayProgress
    }
}