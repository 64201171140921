import { Link, useNavigate } from "react-router-dom";
import React from "react";
import { Helmet } from "react-helmet";
function TermsOfService() {
  const title = "Kajo Spotti - Käyttöehdot"
  const navigate = useNavigate();
  return (
    < div className="main-content" >

      <Helmet>
        <title>{title}</title>
        <meta property="og:title" content={title} />
      </Helmet>
      <div className="popup-container">
        <h2 style={{ alignSelf: "flex-start", margin: 0 }}>
          Käyttöehdot
        </h2>
        <p>Päivitetty 15.9.2024</p>
        <p>
          Kajosolutions.com-verkkopalvelun loppukäyttäjän (jäljempänä ”Käyttäjän”) on luettava ja hyväksyttävä nämä käyttöehdot (jäljempänä “Käyttöehdot”) kokonaisuudessaan. Käyttäjällä tarkoitetaan jäljempänä tapauskohtaisesti joko yksityishenkilöä, yritystä tai muuta toimijaa. Käyttöehdot muodostavat sopimuksen, jota Käyttäjä ja palvelun tuottamisesta vastaava Kajo Solutions Oy (jäljempänä ”Palveluntuottaja”) sitoutuvat asiakassuhteessa noudattamaan.
          <br /><br />
          Palveluntuottaja:<br />
          Kajo Solutions Oy<br />
          Sirritie 10 39160 Julkujärvi Suomi<br />
          info@kajosolutions.com<br />
          kajosolutions.com<br /><br />
          Kajo Solutions Oy on merkitty Patentti- ja rekisterihallituksen ylläpitämään kaupparekisteriin, Y-tunnus 3468301-4. {/*Arvonlisäverotunnus on TODO.*/}<br />
        </p>
        <h3>
          1 Yleistä palvelun käytöstä
        </h3>
        <p>
          1.1 Kajosolutions.com-verkkopalvelun (jäljempänä “Palvelu”) käyttöön sovelletaan näitä Käyttöehtoja. Jos Käyttäjän ja Palveluntuottajan välillä on kuitenkin tehty erillinen palvelusopimus tai Käyttäjä on hyväksynyt Palveluntuottajan tekemän erillisen tarjouksen, joka sisältää näistä Käyttöehdoista poikkeavia ehtoja, sovelletaan kyseisen palvelusopimuksen tai tarjouksen ehtoja ensisijaisesti. Nämä Käyttöehdot täydentävät tällöin erikseen sovittua.<br />

          1.2 Palvelun käyttötarkoituksena on optimoida käyttäjän sähkönkäyttö ajastamalla optimoitavien laitteiden (jäljepänä "Laite") aktiivisuus haluttuun aikaan.<br />

          1.3 Sopimuksen teon tekniset vaiheet ovat Käyttäjäksi rekisteröityminen ja laitteiden käyttöönotto. Käyttöönotetut Laitteet ovat hallittavissa käyttäjän luomalla henkilökohtaisella käyttäjätunnuksella (jäljempänä "Tili").<br />

          1.4 Käyttäjä ei saa toimia tavalla, jonka seurauksena Palvelu lakkaa toimimasta, ylikuormittuu, vahingoittuu tai huononee taikka käytöstä aiheutuu ilmeistä haittaa Palveluntuottajalle. Käyttäjä ei myöskään saa vaikuttaa haitallisesti toisen Käyttäjän Palvelun käyttöön.<br />

          1.5 Käyttäjä vastaa itse Palvelun käyttämiseksi vaadittavien yhteyksien ja Laitteiden hankinnasta sekä niiden toimivuudesta ja asianmukaisesta suojaamisesta aiheutuneista kustannuksista.<br />

          1.6 Palvelun sisältö on lähtökohtaisesti suomeksi. Palveluntuottaja saattaa tarjota mahdollisuutta myös muunkielisen materiaalin käyttämiseen.<br />

          1.7 Käyttäjä sitoutuu kaikessa Palvelun käytössään noudattamaan näiden Käyttöehtojen ohella Suomen lakia ja hyvää tapaa.<br />
        </p>
        <h3>
          2 Käyttöoikeus ja käyttäjätiedot
        </h3>
        <p>
          2.1 Palveluun voi rekisteröityä Käyttäjäksi vain täysi-ikäinen ja muutoin täysivaltainen luonnollinen henkilö taikka kaupparekisteriin tai yhdistysrekisteriin rekisteröity oikeushenkilö. Luonnollinen henkilö tarkoittaa yksityishenkilöä. Oikeushenkilö voi olla yritys, yhdistys, julkisoikeudellinen toimija tai muu oikeushenkilö. Kun näissä Käyttöehdoissa puhutaan yrityksistä, rinnastetaan yrityksiin yleisesti kaikki oikeushenkilöt.<br />

          2.2 Käyttäjän tulee rekisteröityessään antaa vaaditut rekisteröintitiedot (jäljempänä “Käyttäjätiedot”), joiden avulla Käyttäjä voidaan tunnistaa ja yksilöidä. Palvelun käyttämistä varten tulee käyttäjän antaa käyttäjätietona salasanalla suojattu voimassaoleva sähköpostiosoite (jäljempänä "Käyttäjätunnus"). Käyttäjä on velvollinen päivittämään tiedot niiden muuttuessa.<br />

          2.4 Henkilökohtaisen Käyttäjätunnuksen luovuttaminen toiselle yksityishenkilölle Palvelun käyttöä varten on kiellettyä.<br />
        </p>
        <h3>
          3 Palvelun tietosuoja
        </h3>
        <p>
          3.1 Palveluntuottaja säilyttää Käyttäjää koskevat tiedot ja Laitteet luottamuksellisena kattavilla tietoturvatoimenpiteillä. Pääsy ainekseen on ainoastaan Palveluntuottajan omilla työntekijöillä ja niillä Palveluntuottajan kumppaneilla, joilla on asianmukainen rooli Palvelun ylläpidossa ja kehittämisessä.<br />

          3.2 Käyttäjät rekisteröidään Palveluntuottajan käyttäjärekisteriin. Palveluntuottaja käsittelee henkilötietoja Palvelun Rekisteri- ja tietosuojaselosteessa (jäljempänä ”Tietosuojaseloste”) esitettyjen periaatteiden mukaisesti. Käyttäjä hyväksyy periaatteet. Palvelun kulloinkin ajantasaisin tietosuojaseloste on luettavissa Palvelussa osoitteessa <Link to="/privacy" >kajosolutions.com/privacy</Link>.<br />

          3.3 Käyttäjätunnus ja salasana ovat käyttäjäkohtaisia, eikä niitä saa luovuttaa tai ilmaista kolmannelle osapuolelle taikka käsitellä huolimattomasti siten, että Tilille voi päästä joku muu kuin itse Käyttäjä. Salasanan tai Käyttäjätunnuksen tietojen joutuessa ulkopuolisen tietoon Käyttäjän tulee ilmoittaa tästä viipymättä Palveluntuottajalle.<br />

          3.4 Palveluntuottajalla on tietoturvasyistä milloin tahansa oikeus edellyttää Käyttäjää vaihtamaan tämän Käyttäjätunnukseen kuuluva salasana.<br />

          3.7 Käyttäjän itse syöttämien henkilötietojen osalta Käyttäjä on rekisterinpitäjä ja Palveluntuottaja Käyttäjän lukuun toimiva henkilötietojen käsittelijä. Nämä Käyttöehdot yhdessä Tietosuojaselosteen kanssa muodostavat sopimuksen henkilötietojen käsittelystä.<br />
        </p>
        <h3>
          4 Veloitukset palvelun käytöstä
        </h3>
        <p>
          4.1 Palveluun kuuluu sekä maksullisia että maksuttomia osia. Palvelun kulloinkin ajantasaisin hinnasto on luettavissa Palvelussa osoitteessa <Link to="/products" >kajosolutions.com/products</Link> (jäljempänä "Hinnasto").<br />

          4.2 Maksulliset palvelut veloitetaan tilaushetkellä ja uusiutuvissa tilauksissa palvelusopimuksen uusiutumishetkenä, ellei toisin ole sovittu. Tiettyyn käyttöoikeusjaksoon kohdistetut tilaukset veloitetaan vuosittain tai kuukausittain. Vuosiveloitus peritään käyttöoikeusjakson alkaessa, kuukausiveloitus kunkin käyttöoikeusjakson aikavälillä olevan kalenterikuukauden alkaessa. Ellei toisin ole sovittu, veloitus on vuosittain.<br />

          4.3 Jos Käyttäjä on syöttänyt luottokorttitietonsa Käyttäjätietoihin eikä muusta maksutavasta ole sovittu, tehdään veloitukset luottokortilta. Jos luottokorttimaksu ei onnistu, on Palveluntuottajalla oikeus valita maksutavaksi lasku.<br />

          4.4 Palveluntuottaja saattaa tarjota kokeiluluontoisen mahdollisuuden käyttää Palvelun pääsääntöisesti maksullista osakokonaisuutta maksutta tai alennetulla hinnalla erillisessä tarjouksessa määritellyin ehdoin. Ellei tarjouksessa ilmaista toisin, tarjousedun käyttäminen useammin kuin yhden kerran on kiellettyä. Väärinkäytöksi katsotaan esimerkiksi useamman erillisen Tilin luominen, jotta tavanomaisia veloituksia saataisiin teknisesti vältettyä. Tarjouksen käyttäminen useamman kuin yhden kerran luo Palveluntuottajalle välittömän laskutusperusteen vuoden mittaisesta käyttöoikeusjaksosta Hinnaston tai muun erikseen ennalta sovitun hinnaston mukaan.<br />

          4.5 Jos Käyttäjä ei maksa oikea-aikaisesti tilaamiaan tuotteita tai palveluita, Palveluntuottajalla on oikeus rajoittaa Käyttäjän pääsyä Palveluun tai estää Käyttäjän pääsy Tililleen.<br />

          4.6 Ellei toisin ole sovittu, käyttöoikeusjakso veloitetaan kokonaisuudessaan sen alkaessa (käyttöoikeusjakson pituus lähtökohtaisesti vuosi kerrallaan).<br />

          4.7 Jos Käyttäjä on tilannut palveluita kuluttaja-asemassa, hänellä on oikeus peruuttaa tilauksensa neljäntoista (14) päivän kuluessa tilaushetkestä ja saada täysi hyvitys ilmoittamalla peruutuksesta osoitteeseen info@kajosolutions.com.<br />

          4.8 Maksutapahtumissa (esim. luottokortti- ja verkkopankkimaksut) voidaan käyttää ulkopuolisia maksupalveluntarjoajia, jotka veloittavat Käyttäjältä Hinnaston ja näiden Käyttöehtojen mukaiset maksut ja välittävät ne Palveluntuottajalle. Tällaisten kolmansien osapuolten palvelumaksuista vastaa Palveluntuottaja. Maksupalveluntarjoajat ovat lähtökohtaisesti Paytrail Oyj.<br />

          Paytrail maksupalvelutarjoajana<br />

          Kajo Solutions maksunvälityspalvelun toteuttajiin ja maksupalveluntarjoajiin kuuluu Paytrail Oyj (2122839-7) yhteistyössä suomalaisten pankkien ja luottolaitosten kanssa. Paytrail Oyj näkyy maksun saajana tiliotteella tai korttilaskulla ja välittää maksun kauppiaalle. Paytrail Oyj:llä on maksulaitoksen toimilupa. Reklamaatiotapauksissa pyydämme ottamaan ensisijaisesti yhteyttä tuotteen toimittajaan.<br />
          Paytrail Oyj, y-tunnus: 2122839-7<br />
          Innova 2<br />
          Lutakonaukio 7<br />
          40100 Jyväskylä<br />
          https://www.paytrail.com/kuluttaja/tietoa-maksamisesta<br />

          4.9 Jos veloitukset tehdään laskulla, laskusta saatetaan periä kohtuullinen laskutuslisä. Laskun maksuehto on 14 pv netto.<br />
        </p>
        <h3>
          5 Palvelun sisältö ja ylläpito
        </h3>
        <p>
          5.1 Palvelu on lähtökohtaisesti käytettävissä 24 tuntia vuorokaudessa. Palveluntuottajalla on kuitenkin oikeus ottaa Palvelu tai sen osa tilapäisesti pois käytöstä huollon, asennuksen, muutostyön, järjestelmän liiallisen kuormituksen tai muun vastaavan syyn vuoksi ilman vahingonkorvausvastuuta.<br />

          5.2 Palveluntuottaja pyrkii, silloin kuin se on kohtuudella mahdollista, tiedottamaan Palvelussa tapahtuvista, Käyttäjän kannalta olennaisista katkoksista Käyttäjälle etukäteen Käyttäjätiedoissa ilmoitettuun sähköpostiosoitteeseen tai Palvelun välityksellä.<br />

          5.3 Palveluntuottaja ei vastaa mahdollisista vahingoista, jotka aiheutuu Palvelun käytöstä.<br />

          5.4 Palveluntuottaja ei vastaa mistään Käyttäjän itse tuottamasta sisällöstä.<br />

          5.8 Vähäiset tekniset muutokset Tileillä oleviin tietoihin ovat käytettävyys- tai tietoturvasyistä sallittuja.<br />
        </p>
        <h3>
          6 Käyttöehtojen voimassaolo sekä muutokset palvelussa ja hinnastossa
        </h3>
        <p>
          6.1 Käyttöehdot velvoittavat Palveluntuottajaa ja Käyttäjää siitä hetkestä alkaen, kun Palveluntuottaja myöntää Käyttäjälle käyttöoikeuden Palveluun.<br />

          6.2 a) Maksullista käyttöoikeusjaksoa nauttivalla Käyttäjällä on lähtökohtaisesti oikeus käyttää sen veroista ja laatuista Palvelua Hinnaston mukaisin veloituksin kuin viimeisimmän käyttöoikeusjakson alkaessa. Palveluntuottajalla on oikeus tehdä muutoksia Hinnastoon ja Palvelun käyttömahdollisuuksiin käyttöoikeusjakson aikanakin ilman vaikutusta asiakassuhteen muihin ehtoihin sillä edellytyksellä, että muutokset eivät ole suuria tai Käyttäjän kannalta selkeästi kohtuuttomia. Käyttäjään vaikuttavista hinnastomuutoksista ja vähäistä suuremmista Palvelun käyttömahdollisuuksiin liittyvistä muutoksista on ilmoitettava Käyttäjälle tämän Käyttäjätietojen mukaiseen sähköpostiosoitteeseen viimeistään neljä (4) viikkoa ennen muutosten voimaan saattamista, elleivät muutokset ole selkeästi Käyttäjän eduksi.<br />

          b) Veloituksetonta käyttöoikeusjaksoa nauttivalla Käyttäjällä ei ole oikeutta mihinkään määrättyyn hinnoitteluun tai tiettyihin Palvelun ominaisuuksiin. Käyttäjän nykyiseen Palvelun käyttöön vastaisuudessa kohdistettavista maksuista tai Palvelun muuttumista hänen osaltaan maksulliseksi on kuitenkin ilmoitettava Käyttäjätietojen mukaiseen sähköpostiosoitteeseen viimeistään neljä (4) viikkoa ennen muutosten voimaan saattamista.<br />

          c) Palveluntuottajalla on perustellusta syystä oikeus tehdä suuriakin muutoksia Hinnastoon ja Palvelun käyttömahdollisuuksiin myös käyttöoikeusjakson voimassa ollessa, mutta Käyttäjällä on tuolloin oikeus irtisanoa asiakkuutensa ja saada palautetuksi se osuus häneltä mahdollisesti veloitetuista maksuista, jotka kohdistuvat loppuosaan hänen voimassa olevasta käyttöoikeusjaksostaan muutosten tekemisen jälkeen, edellyttäen että muutoksilla on hänen Palvelun käyttöönsä huomattava vaikutus.<br />

          6.3 Maksullista käyttöoikeusjaksoa nauttivaan Käyttäjään sovelletaan sitä versiota näistä Käyttöehdoista, joka oli ajantasaisin ja saatavilla Palvelussa, kun Käyttäjän viimeisin käyttöoikeusjakso käynnistyi. Käyttöoikeusjakson uusiutuessa Käyttäjän katsotaan hyväksyvän kulloinkin ajantasaisimmat Käyttöehdot, ellei Käyttäjä irtisano Palvelua tai ellei Palveluntuottaja ilmoita muuta. Veloituksetonta käyttöoikeusjaksoa nauttivaan Käyttäjään sovelletaan aina ajantasaisimpia Palvelussa saatavilla olevia Käyttöehtoja, jotka Käyttäjä hyväksyy, ellei irtisano Palvelua. Palveluntuottajalla ei ole kuitenkaan oikeutta muuttaa Käyttöehtoja tavalla, joka mahdollistaisi toimenpiteitä ilman Käyttäjän eduksi kohdassa 6.2 asetettujen suoja-aikojen noudattamista.<br />

          6.4 Palveluntuottajalla on oikeus perua tai evätä Palvelun käyttöoikeus ja asiakkuus sellaiselta Käyttäjältä, joka ei ole asiakassuhteessa noudattanut näitä Käyttöehtoja.<br />

          6.5 Käyttöehdot lakkaavat velvoittamasta Käyttäjää ja Palveluntuottajaa Käyttäjän käyttöoikeusjakson loppuessa lukuun ottamatta niitä velvollisuuksia, jotka ovat syntyneet ennen kyseistä ajankohtaa.<br />

          6.6 Palveluntuottaja ja Käyttäjä vapautuvat Käyttöehtojen mukaisista velvoitteistaan siksi ajaksi ja siinä laajuudessa kuin sopimusvelvoitteiden täyttämättä jättäminen johtuu ylivoimaisesta esteestä. Tällainen tapahtuma on esimerkiksi sota, yleinen liikenteen keskeytyminen, energiajakelun keskeytys tai häiriö, laajamittainen työselkkaus, tulipalo, poikkeuksellinen luonnonilmiö tai muu vaikutuksiltaan vastaava ja epätavallinen Käyttäjästä tai Palveluntuottajasta riippumaton syy.<br />

          6.7 Jos jokin näiden Käyttöehtojen kohta on nyt tai myöhemmässä vaiheessa lainvastainen taikka muusta syystä pätemätön tai kannekelvoton, se ei vaikuta muiden kohtien voimassaoloon ja sovellettavuuteen Palvelun käyttöön.<br />
        </p>
        <h3>
          7 Muita ehtoja
        </h3>
        <p>
          7.1 Käyttöehtoihin sovelletaan Suomen lakia. Kaikki niitä koskevat kiistat, erimielisyydet ja vaateet pyritään ensisijaisesti ratkaisemaan osapuolten keskinäisissä neuvotteluissa. Jos osapuolet eivät kykene keskinäisissä neuvotteluissa löytämään ratkaisua, kiistat ratkaistaan Helsingin käräjäoikeudessa.<br />

          7.2 Kuluttajakäyttäjällä on tietyin edellytyksin oikeus saattaa Palvelussa ulkopuolisen elinkeinonharjoittajan kanssa tehtyä sopimusta koskeva riita kuluttajariitalautakunnan käsiteltäväksi. Samoin hän voi toimia mahdollisessa riitatilanteessa Palveluntuottajan kanssa. Ennen asian viemistä kuluttajariitalautakunnan käsittelyyn kuluttajan suositellaan olevan yhteydessä kuluttajaneuvontaan.<br />

          7.3 Palveluntuottajalla on lupa käyttää maksullista käyttöoikeusjaksoa nauttivien yritysasiakkaidensa nimeä ja logoa asiakasreferenssinään mainitsematta asiakkaan luottamuksellisia tietoja. Asiakkaalla on oikeus kieltää referenssin käyttö ilmoittamalla tästä kirjallisesti Palveluntuottajan asiakaspalveluun.<br />

          7.4 Palveluntuottajalla on oikeus siirtää ilman Käyttäjän erillistä hyväksyntää näissä Käyttöehdoissa sille määritetyt oikeudet ja velvollisuudet kolmannelle osapuolelle niin, että uudeksi Palveluntuottajaksi tulee jokin toinen taho kuin Kajo Solutions Oy. Tällainen tilanne voi olla käsillä esimerkiksi tilanteessa, jossa Palvelu tai sen osa siirretään kaupalla toiselle elinkeinonharjoittajalle.<br />
        </p>
        <button className="basic-button" onClick={() => navigate(-1)}>Takaisin</button>
      </div>
    </div >
  );
}
export default TermsOfService;
