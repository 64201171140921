import React, { useContext, useState } from "react";
import { ApiContext } from "../ApiContext";
import { useNavigate, useParams } from "react-router-dom";
import { Toaster, toast } from "react-hot-toast";
import { Helmet } from "react-helmet";

export default function ResetPassword() {
  const title = "Kajo Spotti - Salasanan palautus"
  const navigate = useNavigate();

  const { ApiCall } = useContext(ApiContext);

  const [email, set_email] = useState("");
  const { token } = useParams();
  const [password, set_password] = useState("");
  const [password_confirm, set_password_confirm] = useState("");

  function handleSubmit(event) {
    event.preventDefault();
    if (password !== password_confirm) {
      toast.error("Salasanat eivät täsmää");
      return;
    }
    ApiCall("POST", "user/reset", {
      email: email,
      token: token,
      password: password,
    })
      .then(() => {
        navigate("/login");
      })
      .catch((err) => {
        console.error(err);
        toast.error("Väärä sähköpostiosoite tai palautuslinkki on vanhentunut");
      });
  }

  return (
    <div className="main-content">

      <Helmet>
        <title>{title}</title>
        <meta property="og:title" content={title} />
      </Helmet>
      <Toaster />
      <div className="input-container">
        <div>
          <h2>Uuden salasanan asetus</h2>
        </div>
        <form onSubmit={handleSubmit}>
          <label>Syötä sähköpostiosoitteesi</label>
          <input
            type="email"
            autoFocus={true}
            required={true}
            placeholder="Sähköposti"
            onChange={(e) => {
              set_email(e.target.value);
            }}
          ></input>
          <label>Uusi salasanasi</label>
          <input
            type="password"
            required={true}
            minLength={8}
            placeholder="Salasana"
            id="password"
            onChange={(e) => {
              set_password(e.target.value);
            }}
          ></input>
          <label>Uusi salasanasi uudestaan</label>{" "}
          <input
            type={"password"}
            required={true}
            minLength={8}
            placeholder="Salasana"
            onChange={(e) => {
              set_password_confirm(e.target.value);
            }}
          ></input>
          <input type="submit" value="Lähetä" className="basic-button" />
        </form>
      </div>
    </div>
  );
}
