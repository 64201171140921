import { React, } from "react"
import { Helmet } from "react-helmet"
import { Link } from "react-router-dom"


function Instructions() {
    const title = "Kajo Spotti - Ohjeet"
    const description = "Tutustu ominaisuuksiin ja ohjeisiin kuinka Kajo Spotti toimii"
    return <div className="main-content">
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
        </Helmet>
        <div style={{ maxWidth: "800px" }}>
            <h1 className="additional-info-title section-padding">Kuinka Kajo Spotti toimii?</h1>
            <h2 className="additional-info-subtitle">Käyttöönotto</h2>
            <p className="additional-info-description"><Link to="/register">Rekisteröitymisen</Link> jälkeen voit lisätä latausasemasi <Link to="/device">Laitteet</Link>-sivulla. Latausaseman lisäämisen jälkeen löydät latausaseman OCPP-asetuksista ohjeet latausaseman yhdistämiseksi Kajo Spottiin.</p>
            <p className="additional-info-description">Latausaseman yhdistämisen jälkeen laitteen tila näkyy Online-tilassa, jolloin voit hallita latausaseman toimintaa Spotista. Jos laitteen tilana ei ole Online, tarkista että latausasemalla on toimiva internet-yhteys ja seurasit ohjeita oikein.</p>
            <h2 className="additional-info-subtitle sub-section-padding">Hallinta</h2>
            <p className="additional-info-description">Latausaseman käyttöönoton jälkeen voit asettaa Spotissa latausaseman päälle, pois tai automaattiseen tilaan, jolloin lataus sijoittuu automaattisesti halvimmille tunneille ja säästät sähkölaskussa.</p>
            <p className="additional-info-description">Automaattitila valitsee jokaisesta vuorokaudesta asetusten mukaisesti halvimmat tunnit ja ajoittaa latauksen näihin. Automaattitilaa voidaan ohjata seuraavilla asetuksilla: maksimihinta, lataustuntien määrä vuorokaudessa, latauksen aikaväli ja käytetäänkö peräkkäisiä tunteja.</p>
            <h3 className="additional-info-subsubtitle">Maksimihinta</h3>
            <p className="additional-info-description">Maksimihinnalla voit asettaa hintakaton, jonka haluat maksaa sähköstä enintään. Jos hinta ylittää tämän kynnyksen, ei ladata, vaikka tämä olisikin halvin tunti vuorokaudessa.</p>
            <h3 className="additional-info-subsubtitle">Aktiivisten tuntien määrä</h3>
            <p className="additional-info-description">Aktiivisten tuntien määrällä voidaan määrittää kuinka monta tuntia vuorokaudesta lataus on maksimissaan päällä. Vuorokaudesta valitaan tämän määrän tunteja halvimpien hintojen mukaan latauksen suorittamista varten.</p>
            <h3 className="additional-info-subsubtitle">Aktiivisuuden aikaväli</h3>
            <p className="additional-info-description">Aktiivisuuden aikavälillä voidaan tarkentaa ajankohtaa, josta halvimmat tunnit valitaan. Valitsemalla 0-24 halvimmat tunnit valitaan koko vuorokaudesta, kun taas valitsemalla 21-8 valitaan halvimmat tunnit yöstä iltayhdeksän ja aamukahdeksan väliltä.</p>
            <h3 className="additional-info-subsubtitle">Peräkkäiset tunnit</h3>
            <p className="additional-info-description">Valitsemalla peräkkäiset tunnit ladataan vain peräkkäisinä tunteina, jolloin vuorokaudesta lasketaan yhteensä halvin valittujen tuntien määrän mukainen halvin ajanjakso, jolloin lataus suoritetaan aina yhtajaksoisena ilman katkoksia.</p>
            <h3 className="additional-info-subsubtitle">Demo</h3>
            <p className="additional-info-description">Voit tutustua laitteiden hallintaan helposti ilman rekisteröitymistä <Link to="/sandbox">hiekkalaatikko</Link>-sivulla.</p>
            <h2 className="additional-info-subtitle sub-section-padding">Laitteiden seuranta</h2>
            <p className="additional-info-description"><Link to="/device">Laitteet</Link>-sivulla voit tarkastella lisäämiäsi laitteitasi, näiden tilaa, kuluvan vuorokauden lataussuunnitelmaa ja loppuun suoritettuja latauksia. Lisäksi laitteista esitetään arvioitu säästö kuluvalle vuorokaudelle, joka perustuu asetusten mukaiseen 11 kW teholla latauksen yhteishintaan verrattuna mediaanihintoihin ja maksimihintoihin kyseiseltä vuorokaudelta.</p>
        </div>
    </div>
}

export default Instructions