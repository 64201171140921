import { React, } from "react"

import register from "../../assets/images/register.svg"
import cheapestHours from "../../assets/images/cheapest-hours.svg"
import safe from "../../assets/images/safe.svg"
import timing from "../../assets/images/timing.svg"
import readMore from "../../assets/images/read-more.svg"
import "./Landing.css"
import GetStarted from "./GetStarted"
import FeatureCarousel from "./FeatureCarousel"
import { Link } from "react-router-dom"


function KeyPoint({ icon, title, description, additionalContent = <></> }) {
    return (
        <div className="key-point">
            <img src={icon} className="key-img" alt="kuvituskuva" />
            <div className="key-text">
                <h3 className="key-title">{title}</h3>
                <p className="key-description">{description}</p>
                {additionalContent}
            </div>
        </div >
    )
}

function Landing() {
    return (
        < div className="landing-container" >
            <FeatureCarousel />
            <div className="landing-information" >
                <GetStarted />
                <KeyPoint icon={register} title="Rekisteröi latausasemasi" description="Rekisteröi OCPP-kykyinen latausasemasi palveluumme helposti. Tämän jälkeen palvelussa voit ohjata latausasemaa joko manuaalisesti tai automaattisesti ajastettuna. Rekisteröinnin voi myös helposti purkaa tarvittaessa." />
                <KeyPoint icon={cheapestHours} title="Ajoita lataus halvimmille tunneille" description="Valitse hintakatto ja aktiivisten tuntien lukumäärä. Tämän jälkeen latausasemasi lataa joka päivä automaattisesti pörssisähkön halvimmilla tunneilla, jos hinta alittaa hintakaton. Asetusten asettamisen jälkeen sinun ei tarvitse tehdä mitään." />
                <KeyPoint icon={timing} title="Kattavat asetukset" description="Voit valita automaattiseen ajastukseen aktiivisuusajan vuorokaudesta, josta halvimmat tunnit lasketaan. Esimerkiksi voit asettaa latausaseman lataamaan vain öisin, jolloin latausasema lataa yön halvimpina tunteina, vaikka päivällä olisikin ollut halvempaa sinä vuorokautena." />
                <KeyPoint icon={safe} title="Turvallista" description="Latausaseman ohjaus perustuu avoimeen OCPP-standardiin kryptografisesti salatuilla TLS-tietoliikenneyhteyksillä. Latausasemasi vastaa varsinaisesta latauksen suorittamisesta." />
                <KeyPoint icon={readMore} title="Tutustu lisää" description="" additionalContent={
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <Link className="key-read-more-bullet" to="/information/supported-chargers">Tuetut latausasemat</Link>

                        <Link className="key-read-more-bullet" to="/information/sandbox">Demoympäristö</Link>

                        <Link className="key-read-more-bullet" to="/information">Kajo Spotin käyttö</Link>

                        <Link className="key-read-more-bullet" to="/register">Rekisteröidy</Link>

                    </div>
                } />
            </div>
        </div >
    );
}

export default Landing;