import { React, useRef } from "react"
import { DotButton, useDotButton } from './FeatureCarouselDotButton'
import useEmblaCarousel from 'embla-carousel-react'
import "./FeatureCarousel.css"
import TodayStats from "./TodayStats"
import { ReactComponent as Prices } from "../../assets/images/landing-page-prices.svg";
import { ReactComponent as PricesSettings } from "../../assets/images/landing-page-prices-settings.svg";
import { ReactComponent as PlayIcon } from "../../assets/icons/play-icon.svg";
import { ReactComponent as StopIcon } from "../../assets/icons/stop-icon.svg";
import Autoplay from 'embla-carousel-autoplay'
import { useAutoplay, useAutoplayProgress } from "./FeatureCarouselAutoplay"
import { ReactComponent as ABB } from "../../assets/images/partners/abb.svg";
import { ReactComponent as Easee } from "../../assets/images/partners/easee.svg";
import { ReactComponent as Malmbergs } from "../../assets/images/partners/malmbergs.svg";
import { ReactComponent as Schneider } from "../../assets/images/partners/schneider.svg";
import { ReactComponent as Zaptec } from "../../assets/images/partners/zaptec.svg";
import { ReactComponent as Garo } from "../../assets/images/partners/garo.svg";

function Slide({ title, desc, content, picture, gradientStart = "#00000090", gradientEnd = "#00000060" }) {
    return <>
        <div className="slide-content" style={{
            width: "100%",
            height: "100%",
            backgroundImage: "linear-gradient(to right, " + gradientStart + ", " + gradientEnd + "),url(./" + picture + ")",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
        }}>
            <div className="slide-content-container">
                <div>
                    <h1 className="slide-title" >{title}</h1>
                    <p className="slide-description" >
                        {desc}
                    </p>
                </div>
                {content}
            </div>
        </div>
    </>
}

const options = { loop: true }
const partnerStyle = {
    display: "flex",
    flex: "0 0 25%",
    padding: "10px",
    justifyContent: "center",
    alignItems: "center",
}
const illustrationStyle = {
    marginTop: "2em", width: "100%", height: "100%", maxWidth: "450px"
}
const slides = [
    Slide({
        title: "Kajo Spotti",
        desc: "Optimoi pörssisähkön kulutuksesi ja säästä sähkölaskussa helposti lataamalla halvimpaan aikaan",
        content: <TodayStats />,
        picture: "landing-page-1.webp",
        gradientStart: "#000000aa",
        gradientEnd: "#00000060",
    }),
    Slide({
        title: "Säästä latauksessa",
        desc: "Säästä helposti sähköauton latauksessa lataamalla pörssisähkön halvimpaan aikaan",
        content:
            <div style={{ display: "flex", width: "100%", justifyContent: "center" }} >
                <div style={{ display: "flex", width: "100%", maxWidth: "80vw", alignItems: "center", justifyContent: "center" }}>
                    <Prices style={illustrationStyle} />
                </div>
            </div>,
        picture: "landing-page-2.webp",
        gradientStart: "#00000030",
        gradientEnd: "#00000090",

    }),
    Slide({
        title: "Kattavat asetukset",
        desc: "Valitse hintakatto ja vuorokaudesta sinulle sopivat tunnit, joista halvimmat lataustunnit valitaan automaattisesti",
        content:
            <div style={{ display: "flex", width: "100%", justifyContent: "center" }} >
                <div style={{ display: "flex", width: "100%", maxWidth: "80vw", alignItems: "center", justifyContent: "center" }}>
                    <PricesSettings style={illustrationStyle} />
                </div>
            </div>,
        picture: "landing-page-3.webp",
        gradientStart: "#00000099",
        gradientEnd: "#00000030",
    }),
    Slide({
        title: "Paljon tuettuja latausasemia",
        desc: "Helppo ottaa käyttöön alle vartissa",
        content: <div style={{ marginTop: "2em", display: "flex", flexWrap: "wrap" }} >
            <div style={partnerStyle}>
                <Easee style={{ width: "100%" }} />
            </div>
            <div style={partnerStyle}>
                <Zaptec style={{ width: "100%" }} />
            </div>
            <div style={partnerStyle}>
                <Malmbergs style={{ width: "100%" }} />
            </div>
            <div style={partnerStyle}>
                <ABB style={{ width: "100%" }} />
            </div>
            <div style={partnerStyle}>
                <Schneider style={{ width: "100%" }} />
            </div>
            <div style={partnerStyle}>
                <Garo style={{ width: "100%" }} />
            </div>
        </div >,
        picture: "landing-page-4.webp",
        gradientStart: "#000000aa",
        gradientEnd: "#00000040",
    }),
]

function FeatureCarousel() {
    const progressNode = useRef(null)
    const [emblaRef, emblaApi] = useEmblaCarousel(options, [
        Autoplay({ playOnInit: true, delay: 7000 })
    ])

    const { selectedIndex, scrollSnaps, onDotButtonClick } =
        useDotButton(emblaApi)

    const { autoplayIsPlaying, toggleAutoplay } =
        useAutoplay(emblaApi)

    const { showAutoplayProgress } = useAutoplayProgress(emblaApi, progressNode)

    const autoplayToggleOnClick = (e) => {
        e.preventDefault()
        toggleAutoplay()
    }

    return (
        <section className="embla">
            <div className="embla__viewport" ref={emblaRef}>
                <div className="embla__container">
                    {slides.map((slide, index) => (
                        <div className={'embla__slide'.concat(
                            index === selectedIndex ? "" : ' embla__slide--not-selected'
                        )} key={index}>
                            {slide}
                        </div>
                    ))}
                </div>
            </div>

            <div className="embla__controls">

                <div className="embla__around__dots" />
                <div className="embla__dots">
                    {scrollSnaps.map((_, index) => (
                        <DotButton
                            key={index}
                            onClick={(e) => {
                                e.preventDefault()
                                if (autoplayIsPlaying) {
                                    toggleAutoplay()
                                }
                                onDotButtonClick(index)
                            }
                            }
                            className={'embla__dot'.concat(
                                index === selectedIndex ? ' embla__dot--selected' : ''
                            )}
                        />
                    ))}
                </div>
                <div className="embla__around__dots" >
                    <div className={`embla__progress`.concat(showAutoplayProgress ? '' : ' embla__progress--hidden')}>
                        <div className="embla__progress__bar" ref={progressNode} />
                    </div>
                    <div className="embla__autoplay__toggle" onClick={autoplayToggleOnClick}>
                        {autoplayIsPlaying ? <StopIcon className="embla__autoplay__toggle__icon" /> : <PlayIcon className="embla__autoplay__toggle__icon" />}

                    </div>
                </div>
            </div>
        </section >
    )
}

export default FeatureCarousel
