import React, { useContext, useState } from "react";
import { ApiContext } from "../ApiContext";
import { Toaster, toast } from "react-hot-toast";
import { Helmet } from "react-helmet";

export default function ForgotPassword() {
  const title = "Kajo Spotti - Salasanan palautus"
  const { ApiCall, ga4 } = useContext(ApiContext);

  const [sent, set_sent] = useState(false);
  const [email, set_email] = useState("");

  function handleSubmit(event) {
    event.preventDefault();
    ApiCall("POST", "user/forgot", { email: email })
      .then((res) => {
        console.log(res);
        set_sent(true);
        ga4.event({
          category: "user",
          action: "forgot_password_request",
          label: "User requested password reset",
        })
      })
      .catch((err) => {
        console.error(err);
        if (err.response.status === 400) {
          toast.error("Ei tiliä sähköpostilla");
        }
      }).catch((err) => {
        console.error(err)
      })
  }
  if (sent) {
    return (
      <div className="main-content">
        <Helmet>
          <title>{title}</title>
          <meta property="og:title" content={title} />
        </Helmet>
        <div className="input-container">
          <div >
            <h2 >Salasanan palautus</h2>
            <p>
              Palautuslinkki on lähetetty sähköpostilla. Tämän ikkunan voi
              sulkea.
            </p>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="main-content">
        <Helmet>
          <title>{title}</title>
          <meta property="og:title" content={title} />
        </Helmet>
        <Toaster />
        <div className="input-container">
          <h1>Salasanan palautus</h1>
          <form onSubmit={handleSubmit}>
            <input
              type="email"
              autoFocus={true}
              required={true}
              placeholder="Syötä sähköpostiosoitteesi"
              onChange={(e) => {
                set_email(e.target.value);
              }}
            ></input>
            <p>
              Palautuslinkki lähetetään sähköpostilla. Uusi salasana asetetaan
              painamalla sähköpostin linkkiä.
            </p>
            <br></br>
            <input
              type="submit"
              value="Lähetä linkki"
              className="basic-button"
            />
          </form>
        </div>
      </div>
    );
  }
}
