import { React, useContext, useState } from "react"
import { useNavigate } from "react-router-dom"
import { ApiContext } from "../../ApiContext"

import "./GetStarted.css"
function GetStarted() {
    const { ApiCall, user, ga4 } = useContext(ApiContext)
    const [email, setEmail] = useState("")
    const navigate = useNavigate()

    function handleSubmit(event) {
        event.preventDefault();
        ga4.event({
            category: "user",
            action: "get_started",
            label: "User submitted get started",
        })
        ApiCall("POST", "user/welcome", { email: email })
            .then((res) => {
                console.log(res)
                if (res && res.new) {
                    navigate("/register/" + email);
                }
                else {
                    navigate("/login/" + email);
                }
            })
            .catch((err) => {
                console.error(err);
            });
    }
    return (
        <div className="get-started-container">
            <form onSubmit={handleSubmit} className="get-started-form" style={{ maxWidth: "700px" }}>
                <p className="get-started-sub-title"> Tarvitset vain sähköauton, pörssisähkösopimuksen ja internet-yhteydellisen latausaseman</p>
                <div className="get-started-input-container">
                    {!user && <input type="email" className="get-started-input" required={true} placeholder="Syötä sähköpostiosoitteesi" style={{ backgroundColor: "white" }}
                        onChange={(e) => {
                            setEmail(e.target.value)
                        }} />}
                    {!user && <input type="submit" value="Jatka" className="basic-button get-started-button" style={{ height: "50px" }} />}
                    {user && <button className="basic-button get-started-button" style={{ marginBottom: "80px" }}
                        onClick={e => {
                            e.preventDefault()
                            navigate("/device")
                        }}>Hallitse laitteitasi</button >}
                </div>
            </form>
        </div>
    )
}

export default GetStarted