import "./Header.css";
import React, { useContext, useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { ReactComponent as IoMenu } from "../assets/icons/menu-burger.svg";
import { ReactComponent as IoClose } from "../assets/icons/menu-close.svg";
import { ApiContext } from "../ApiContext";

const Header = () => {
  const navigate = useNavigate()
  const { user, logout } = useContext(ApiContext);
  const [showMenu, setShowMenu] = useState(false);

  const [onTop, setOnTop] = useState(true);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  useEffect(() => {
    console.log(location.pathname);
  }, [location]);

  const closeMenuOnMobile = () => {
    if (window.innerWidth <= 1150) {
      setShowMenu(false);
    }
  };

  document.addEventListener('scroll', function () {
    if (window.scrollY > 0) {
      setOnTop(false);
    } else {
      setOnTop(true);
    }
  });
  return (
    <div className="nav-parent" style={{ boxShadow: onTop && "none", backgroundColor: onTop && "#EBF2ED00" }}>
      <div onClick={e => {
        e.preventDefault()
        navigate("/")
      }} style={{ display: "flex", cursor: "pointer" }}>
        <img alt="logo" src={"/logo192.png"} className="nav-logo" />
        <h2>
          Kajo Spotti
        </h2>
      </div>
      <nav className="nav-links">
        <div className={`nav-menu ${showMenu ? "show-menu" : ""}`}>
          <ul className="nav-list">
            <li className={location.pathname == "/" ? "nav-item-selected" : "nav-item"}>
              <NavLink to="/" className="nav-link nav" onClick={closeMenuOnMobile}>
                Spotti
              </NavLink>
            </li>
            <li className={location.pathname == "/about" ? "nav-item-selected" : "nav-item"}>
              <NavLink to="/about" className="nav-link nav" onClick={closeMenuOnMobile}>
                Meistä
              </NavLink>
            </li>
            <li className={location.pathname == "/prices" ? "nav-item-selected" : "nav-item"}>
              <NavLink
                to="/prices"
                className="nav-link nav"
                onClick={closeMenuOnMobile}
              >
                Spot-hinta
              </NavLink>
            </li>
            {user && <li className={location.pathname.startsWith("/device") ? "nav-item-selected" : "nav-item"}>
              <NavLink
                to="/device"
                className="nav-link nav"
                onClick={closeMenuOnMobile}
              >
                Laitteet
              </NavLink>

            </li>
            }
            {user && <li className={location.pathname == "/profile" ? "nav-item-selected" : "nav-item"}>
              <NavLink
                to="/profile"
                className="nav-link nav"
                onClick={(e) => {
                  closeMenuOnMobile()
                }}
              >
                {user}
              </NavLink>
            </li>}
            {user && <li className={location.pathname == "/logout" ? "nav-item-selected" : "nav-item"}>
              <NavLink
                to="/logout"
                className="nav-link nav"
                onClick={(e) => {
                  closeMenuOnMobile()
                  logout()
                }}
              >
                Kirjaudu ulos
              </NavLink>
            </li>
            }
            {!user && <li className={location.pathname == "/login" ? "nav-item-selected" : "nav-item"}>
              <NavLink
                to="/login"
                className="nav-link nav"
                onClick={closeMenuOnMobile}
              >
                Kirjaudu sisään
              </NavLink>
            </li>
            }
          </ul>
        </div>
        <div className="nav-toggle">
          <button aria-label="Menu" className="circle-button nav-menu-button" onClick={toggleMenu}>
            {!showMenu && <IoMenu />}
            {showMenu && <IoClose />}
          </button>
        </div>
      </nav>
    </div >
  );
};

export default Header;