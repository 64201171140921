import { React, } from "react"
import ChargerSelection from "../ChargerSelection"
import { Link } from "react-router-dom"
import { Helmet } from "react-helmet"

function SupportedChargersInformation() {
    const title = "Kajo Spotti - Tuetut latausasemat"
    const description = "Kajo Spotissa toimivat kaikki latausasemat, jotka toteuttavat OCPP 1.6J -standardin."
    return <div className="main-content">
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
        </Helmet>
        <div style={{ maxWidth: "800px" }}>
            <h1 className="additional-info-title">Tuetut latausasemat</h1>
            <p className="additional-info-description">Kajo Spotissa toimivat kaikki latausasemat, jotka toteuttavat OCPP 1.6J -standardin. Valtaosa markkinoilla olevista älylatausasemista toteuttaa OCPP 1.6J-standardin. Hyvin todennäköistä, että jos latausasemassasi on internet-yhteys (WiFi/ethernet), on se myös yhteensopiva palvelumme kanssa.</p>
            <p className="additional-info-description">Seuraavavien latausasemien rekisteröinnille löytyy palvelustamme ohjeet ja nämä on validoitu yhteensopiviksi:</p>

            <ChargerSelection selectedCharger={null} setSelectedCharger={(e) => console.log(e)} showOther={false} />
            <p className="additional-info-description">Eikö latausasemaasi näy listassa? Ota yhteyttä sähköpostilla <Link to="mailto:info@kajosolutions.com?subject=Uuden latausaseman lisäys&Body=Hei,%0D%0Ahaluaisin tuen latausasemalleni">info@kajosolutions.com</Link> ja validoidaan yhdessä latausasemasi yhteensopivuus.</p>
            <h2 className="additional-info-subtitle">Open Charge Point Protocol (OCPP)</h2>
            <p className="additional-info-description">Open Charge Point Protocol (OCPP) on avoin standardisoitu tiedonsiirtoprotokolla latausasemille. OCPP mahdollistaa latausaseman hallinnan turvallisesti keskitetystä palvelusta (Charging Station Management System, CSMS). </p>
            <p className="additional-info-description">CSMS-palvelu voi esimerkiksi asettaa latausasemalle latausprofiilin, jolla lataamisen ajankohtaa voidaan hallita. Tällöin latauksesta vastaa kuitenkin aina paikallisesti latausasema ja CSMS-palvelusta saadaan vain korkean tason käyttösuunnitelma.</p>
        </div>
    </div>
}

export default SupportedChargersInformation